/*
 * Thanks to
 * https://www.springboottutorial.com/spring-boot-react-full-stack-crud-maven-application
 * for the tutorial, that allowed me to know
 * about axios (framework for making REST API requests)
 * and the details
 */
 import axios from 'axios'

/*
 * Now we are 3.0.0 31.05.2021 10:19, and the time has come to
 * attempt cluster integration. On the cluster, the matching tool
 * has an internal service endpoint: matching-tool.fub:8080
 * And now comes a question. Is it possibly to dinamically
 * decide whether we should have localhost:8080 or matching-tool.fub:8080?
 * I can definitely make a boolean to make the switch easier..
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * 3.0.0 03.06.2021 11:14 -
 * Thanks to https://blog.logrocket.com/multiple-entry-points-in-create-react-app-without-ejecting/,
 * (also https://create-react-app.dev/docs/adding-custom-environment-variables/)
 * we have a better option. We will have three environment files.
 * .env.local, which will be docker ignored, and takes precedence over .env.development with http://localhost:8080
 * .env.development, which takes effect with npm start in the image and has http://matching-tool.fub:8080
 * .env.production, which takes effect with npm run build and has http://matching-tool.fub:8081
 */
const DASHBOARD_API_URL = process.env.REACT_APP_BACKEND
const MATCHING_API_URL = `${DASHBOARD_API_URL}/produce_matching`
const SCORES_API_URL = `${DASHBOARD_API_URL}/answer_scores`
const WEIGHTS_API_URL = `${DASHBOARD_API_URL}/question_weights`
const NAMES_API_URL = `${DASHBOARD_API_URL}/matching_names`
const META_API_URL = `${DASHBOARD_API_URL}/matching_meta`
const LOGIN_API_URL = `${DASHBOARD_API_URL}/login`
const CODES_API_URL = `${DASHBOARD_API_URL}/question_codes`
const DEPENDENCY_API_URL = `${DASHBOARD_API_URL}/dependencies`
const INCLUSION_API_URL = `${DASHBOARD_API_URL}/question_inclusion`
const CALCULATION_API_URL = `${DASHBOARD_API_URL}/question_calculation`
// UPDATE202107011033 - 3.1.0 01.07.2021 10:53
const IS_BINARY = "isBinary";
const IS_INCLUDED = "isIncluded";
const WEIGHT = "weight";

class MatchingDashboardBackEndCommunicator {
  //constructor() {

  //}

  produceMatching(metaName) { // added metaName 3.0.0 04.05.2021 13:16
    return axios.get(`${MATCHING_API_URL}`, { params: { metaName: metaName }});
  }

  /**
   * @implNote 2.1.0 08.12.2020 12:47
   * Added the config to try and accept
   * xml from the backend
   * @implNote Added the metaName parameter 3.0.0 03.05.2021 13:23
   * @implNote 3.2.0 27.07.2021 22:22 - No longer accepts xml since now from db
   * @since 2.1.0 04.12.2020 14:51
   */
  getScores(metaName) {
    /*var config = {
    //  headers: {'Content-Type': 'application/xml', 'Accept': 'application/xml'}
      //headers: {'Content-Type': 'text/xml', 'Accept': 'text/xml'} // 2.1.0 08.12.2020 13:25 try to fix bug of empty response
      headers: {'Accept': 'application/xml'} // 2.1.0 08.12.2020 13:41 try to fix bug of empty response
    };*/
    //return axios.get(`${SCORES_API_URL}`);
    return axios.get(`${SCORES_API_URL}`, { params: { metaName: metaName }});
  }

  /**
   * Sends scores (possibly updated)
   * to the backend
   * @implNote 3.2.0 27.07.2021 22:22 - No longer sends xml since now from db
   * @since 2.1.0 18.12.2020 11:53
   */
  //updateScores(xmlDocument) {
  updateScores(scores) {
  /*  var config = {
     //headers: {'Content-Type': 'application/xml'}
     // Attempt to sent a text/xml with documentElement.outerHTML 2.1.0 21.12.2020 14:46
     headers: {'Content-Type': 'text/xml'}
   };*/
    //axios.post(`${SCORES_API_URL}`, xmlDocument, config);
    //console.log(xmlDocument.documentElement.outerHTML);
    //axios.post(`${SCORES_API_URL}`, xmlDocument.documentElement.outerHTML, config);
    axios.post(`${SCORES_API_URL}`, scores);
    /*axios.post(`${SCORES_API_URL}`, scores, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        } // Thanks ghost on https://github.com/axios/axios/issues/1552 for the tip
      }) // From here on out, it's only for logging and debugging and can be discarded
    .then((response) => { // Thanks willieswanjala,https://stackoverflow.com/questions/60120909/axios-400-bad-request-in-react for error logging advice
      console.log(response);
    }, (error) => {
      if (error.response){
        console.log(error.response);
      }else if(error.request){
        console.log(error.request);
      }else if(error.message){
        console.log(error.message);
      }
    });*/
  }

  /**
   * Sends an xml document (possibly updated)
   * to the backend
   * @since 2.1.0 22.12.2020 12:08
   * @depracated I think 3.1.0
   */
  updateWeights(xmlDocument) {
    var config = {
     headers: {'Content-Type': 'text/xml'}
   };
    axios.post(`${WEIGHTS_API_URL}`, xmlDocument.documentElement.outerHTML, config);
  }

  /**
   * Gets the relevantNames
   * @implNote Added headers 3.0.0 25.05.2021 11:14
   * @since 3.0.0 27.04.2021 12:41
   */
  getNames(username) {
    return axios.get(`${NAMES_API_URL}`, { params: { username: username },
      //headers: {
      //    "Content-Type": "application/json",
      //    "Access-Control-Allow-Origin": "*",
      //    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      //}
     }
   );
  }

  /**
   * Submits the data of the created matchingMeta
   * and the username for the first permission.
   * @apiNote 3.2.0 28.07.2021 14:52 now returns a value
   * (~15:40) now from inside the responses
   * (15:42) now async
   * (15:54) No longer async. You need two return - one before axios.post, and
   * the ones for the response.
   * @implNote 3.3.0 06.08.2021 14:38 added matchingMethod
   * (16:34) added this.edit
   * 4.0.0 22.09.2021 10:01 matchingMethod isn't really necessary and
   * will be removed
   * @since 3.0.0 29.04.2021 14:07
   */
  submitMatchingMeta(username, metaName, metaType, maxGroupSize, mentorSurveyId, menteeSurveyId, edit) {
    return axios.post(`${META_API_URL}`, {
      username: username,
      metaName: metaName,
      metaType: metaType,
      maxGroupSize: maxGroupSize,
      mentorSurveyId: mentorSurveyId,
      menteeSurveyId: menteeSurveyId,
      edit: edit
    }, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        } // Thanks ghost on https://github.com/axios/axios/issues/1552 for the tip
      }) // From here on out, it's only for logging and debugging and can be discarded
    .then((response) => { // Thanks willieswanjala,https://stackoverflow.com/questions/60120909/axios-400-bad-request-in-react for error logging advice
      console.log(response);
      return response;
    }, (error) => {
      if (error.response){
        console.log(error.response);
      }else if(error.request){
        console.log(error.request);
      }else if(error.message){
        console.log(error.message);
      }
      return error;
    });
  }

  /**
   * Gets the data of the matchingMeta.
   * @since 3.3.0 06.08.2021 15:55
   */
  getMatchingMeta(metaName) {
    return axios.get(`${META_API_URL}`, {params: { metaName: metaName},}) // From here on out, it's only for logging and debugging and can be discarded
    .then((response) => { // Thanks willieswanjala,https://stackoverflow.com/questions/60120909/axios-400-bad-request-in-react for error logging advice
      console.log(response);
      return response;
    }, (error) => {
      if (error.response){
        console.log(error.response);
      }else if(error.request){
        console.log(error.request);
      }else if(error.message){
        console.log(error.message);
      }
      return error;
    });
  }

  /**
   * Attempts to login to the wordpress user.
   * @since 3.0.0 28.05.2021 11:57
   */
  login(username, password) {
    // Thanks https://flaviocopes.com/axios-send-authorization-header/
    //console.log(username);
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    //console.log(token);
    return axios.get(`${LOGIN_API_URL}`, {
      headers: {
          'Authorization': `Basic ${token}`
        },
    }
    );
  }

  /**
   * Gets the question codes.
   * @since 3.1.0 18.06.2021 09:48
   */
  getCodes(matchingName) {
    return axios.get(`${CODES_API_URL}`, { params: { metaName: matchingName },
     }
   );
  }

  /**
   * Submits the data of the created dependency.
   * @since 3.1.0 18.06.2021 14:47
   */
  submitDependency(dependentCode, dependeeCode, type, relation, threshold,
    noYes, yesNo, noNo, isIndependentBinary, metaName) {
    axios.post(`${DEPENDENCY_API_URL}`, {
      dependentCode: dependentCode,
      dependeeCode: dependeeCode,
      type: type,
      relation: relation,
      threshold: threshold,
      noYes: noYes,
      yesNo: yesNo,
      noNo: noNo,
      isIndependentBinary: isIndependentBinary,
      metaName: metaName
    }, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        } // Thanks ghost on https://github.com/axios/axios/issues/1552 for the tip
      }) // From here on out, it's only for logging and debugging and can be discarded
    .then((response) => { // Thanks willieswanjala,https://stackoverflow.com/questions/60120909/axios-400-bad-request-in-react for error logging advice
      console.log(response);
    }, (error) => {
      if (error.response){
        console.log(error.response);
      }else if(error.request){
        console.log(error.request);
      }else if(error.message){
        console.log(error.message);
      }
    });
  }

  /**
   * Gets the question dependencies.
   * @since 3.1.0 21.06.2021 14:33
   */
  getDependencies(matchingName) {
    return axios.get(`${DEPENDENCY_API_URL}`, { params: { metaName: matchingName },
     }
   );
  }

  /**
   * Deletes the selected question dependencies.
   * @since 3.1.0 22.06.2021 10:53
   */
  deleteDependencies(deletionCandidates, matchingName) {
    return axios.delete(`${DEPENDENCY_API_URL}`,  {
      data: {
        metaName: matchingName,
        dependencies: deletionCandidates
      },
         headers: {
             "Content-Type": "application/json",
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
         }, // Thanks ghost on https://github.com/axios/axios/issues/1552 for the tip
       }
   ).then((response) => { // Thanks willieswanjala,https://stackoverflow.com/questions/60120909/axios-400-bad-request-in-react for error logging advice
     console.log(response);
   }, (error) => {
     if (error.response){
       console.log(error.response);
     }else if(error.request){
       console.log(error.request);
     }else if(error.message){
       console.log(error.message);
     }
   });
  }

  /**
   * Attempts to submit the new question inclusions
   * ~!~!!~!~
   * (13:37) updated to include a boolean of isInclusion
   * that sets a new path variable to either INCLUSION_API_URL
   * or CALCULATION_API_URL, since that's really the only
   * difference (the contents are the same), so I realized
   * it would be dumb to have a separate function
   * @implNote UPDATE202107011033 - 3.1.0 01.07.2021 10:52
   * will do a switch case to decide on a path..
   * @since 3.1.0 29.06.2021 11:04
   */
  submitConfigs(codeObjects, matchingName, type) {
    var path = ``;
    switch (type) {
      case IS_INCLUDED:
      path = `${INCLUSION_API_URL}`;
      break;
      case IS_BINARY:
      path = `${CALCULATION_API_URL}`;
      break;
      case WEIGHT:
      path = `${WEIGHTS_API_URL}`;
      break;
      default:

    }
    axios.post(path, {
      codeObjects: codeObjects,
      metaName: matchingName
    }, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        } // Thanks ghost on https://github.com/axios/axios/issues/1552 for the tip
      }) // From here on out, it's only for logging and debugging and can be discarded
    .then((response) => { // Thanks willieswanjala,https://stackoverflow.com/questions/60120909/axios-400-bad-request-in-react for error logging advice
      console.log(response);
    }, (error) => {
      if (error.response){
        console.log(error.response);
      }else if(error.request){
        console.log(error.request);
      }else if(error.message){
        console.log(error.message);
      }
    });
  }

  testGet() {
    return axios.get('http://localhost:8080/test_get');
  }
}

export default new MatchingDashboardBackEndCommunicator();
